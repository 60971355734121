import React from 'react';



class News extends React.Component {

    render() {
      
      return (
        <div>
          <p>News</p>
         </div>
      );
    }
  }
  

export default News;
