import React from 'react';



class AboutUs extends React.Component {

    render() {
      
      return (
        <div>
          <p>About Us</p>
         </div>
      );
    }
  }
  

export default AboutUs;
