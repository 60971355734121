import React from 'react';

import BaseApp from './BaseApp.js';
import AppSummary from './AppSummary.js';


class OfficeUndead extends React.Component {

    render() {
      
        var shot1 = require('./images/office_undead/shot_1.jpg');
     

      const shortDescription =
      `Our first ever game! This was the very first game developed by Abnormal Head
      `;
      const longDescription = `
        This game was designed to help us start to learn how to make games. It was built quite a while ago and we have stopped updating it. It sets out to make fun of the office life in a tower defense style game. You defend yourself against office zombies with coffee and fax machines. One day, we may bring this idea back.
        
        You always suspected the people you work with are undead, it turns out that was true.

        It was a miserable place to work. You felt the pain in the pit of your stomach every morning on the way to work. You remained strong and your spirit was unbroken. now, you are about to be tested in a way you never would have imagined.

        You refused to go to the meeting. It's a good thing you did, because everyone that went to that meeting has turned into zombies. Now you and your friends must defend yourselves!

        You must survive. Set up automatic weapons from seemingly simple objects found at the office. They will fire at the incoming hoards of angry zombies.

        Use temporary workers as suicide bombers!
        `;

    if(this.props.summary === true)
      return (
        <AppSummary appName = 'Office Undead'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        route = {"/apps/wind-heroes"}
                        appStoreLocation = "https://apps.apple.com/gb/app/office-undead/id528991079"
                        shot = {shot1}
                        />
      );
      else
      return(
        <BaseApp appName = 'Office Undead'
                        shortDescription = {shortDescription} 
                        longDescription = {longDescription}
                        appStoreLocation = "https://apps.apple.com/gb/app/office-undead/id528991079"
                        shots = {[shot1]}
                        />
      
      );
    }
    

  }
  

export default OfficeUndead;
